/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n      mutation updateAddress($input: UpdateAddressInput!) {\n        updateAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    ": types.UpdateAddressDocument,
    "\n      mutation createAddress($input: CreateAddressInput!) {\n        createAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    ": types.CreateAddressDocument,
    "\n      mutation deleteAddress($input: DeleteAddressInput!) {\n        deleteAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    ": types.DeleteAddressDocument,
    "\n    query getOrder($input: OrderInput!) {\n      order(input: $input) {\n        id\n        values\n        gift {\n          name\n          image\n          description\n          form\n          uid\n        }\n      }\n    }\n  ": types.GetOrderDocument,
    "\n      mutation updateOrder($input: UpdateOrderInput!) {\n        updateOrder(input: $input) {\n          id\n          values\n        }\n      }\n    ": types.UpdateOrderDocument,
    "\n    query getOrders {\n      user {\n        orders {\n          id\n          gift {\n            name\n            image\n            uid\n          }\n        }\n      }\n    }\n  ": types.GetOrdersDocument,
    "\n    mutation login($input: LoginInput!) {\n      login(input: $input)\n    }\n  ": types.LoginDocument,
    "\n  query getAddresses {\n    user {\n      addresses {\n        id\n        name\n        phone\n        region\n        detail\n      }\n    }\n  }\n": types.GetAddressesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation updateAddress($input: UpdateAddressInput!) {\n        updateAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    "): (typeof documents)["\n      mutation updateAddress($input: UpdateAddressInput!) {\n        updateAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation createAddress($input: CreateAddressInput!) {\n        createAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    "): (typeof documents)["\n      mutation createAddress($input: CreateAddressInput!) {\n        createAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation deleteAddress($input: DeleteAddressInput!) {\n        deleteAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    "): (typeof documents)["\n      mutation deleteAddress($input: DeleteAddressInput!) {\n        deleteAddress(input: $input) {\n          id\n          name\n          phone\n          region\n          detail\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getOrder($input: OrderInput!) {\n      order(input: $input) {\n        id\n        values\n        gift {\n          name\n          image\n          description\n          form\n          uid\n        }\n      }\n    }\n  "): (typeof documents)["\n    query getOrder($input: OrderInput!) {\n      order(input: $input) {\n        id\n        values\n        gift {\n          name\n          image\n          description\n          form\n          uid\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation updateOrder($input: UpdateOrderInput!) {\n        updateOrder(input: $input) {\n          id\n          values\n        }\n      }\n    "): (typeof documents)["\n      mutation updateOrder($input: UpdateOrderInput!) {\n        updateOrder(input: $input) {\n          id\n          values\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getOrders {\n      user {\n        orders {\n          id\n          gift {\n            name\n            image\n            uid\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query getOrders {\n      user {\n        orders {\n          id\n          gift {\n            name\n            image\n            uid\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation login($input: LoginInput!) {\n      login(input: $input)\n    }\n  "): (typeof documents)["\n    mutation login($input: LoginInput!) {\n      login(input: $input)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAddresses {\n    user {\n      addresses {\n        id\n        name\n        phone\n        region\n        detail\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAddresses {\n    user {\n      addresses {\n        id\n        name\n        phone\n        region\n        detail\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
